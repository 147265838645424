'use strict';
export * from '@/common/utils/module/ParameterAssistant';
export * from '@/common/utils/module/FormDataSupport';
export * from '@/common/utils/module/Supplementary';
export * from '@/common/utils/module/ValidCustomMessage';
export * from '@/common/utils/module/XlsxSupport';
export * from '@/common/utils/module/ValidSupport';
export * from '@/common/utils/module/ScrollSupport';
export * from '@/common/utils/module/MaskingSupport';
export * from '@/common/utils/module/FormattingSupport';
export * from '@/common/utils/module/SpatialSupport';
export * from '@/common/utils/module/TransformSupport';
export * from '@/common/utils/module/ToastSupport';
export * from '@/common/utils/module/FlowSupport';
export * from '@/common/utils/module/FreezeSearchConditionSupport';
export * from '@/common/constants/ConstCode';
export * from '@/common/utils/module/RegularExpressionSupport';
import { PROFILE, CODE_ENV_LIST } from '@/common/constants/ConstCode';
import dayjs from 'dayjs';

export const isEmpty = (value) => {
  return (
    value === '' || value === null || value === undefined || (typeof value === 'object' && !Object.keys(value).length)
  );
};

/**
 * 연속된 비밀번호 사용인지 여부 판단
 * @param value
 * @returns {boolean}
 */
export const isContinueousVaild = (value) => {
  let userPwPassed = true;
  let SamePass0 = 0; // 동일문자 카운트
  let SamePass1 = 0; // 연속성(+) 카운드
  let SamePass2 = 0; // 연속성(-) 카운드
  for (let i = 0; i < value.length; i++) {
    let chrPass0;
    let chrPass1;
    let chrPass2;
    if (i >= 2) {
      chrPass0 = value.charCodeAt(i - 2);
      chrPass1 = value.charCodeAt(i - 1);
      chrPass2 = value.charCodeAt(i);
      // 동일문자 카운트
      if (chrPass0 === chrPass1 && chrPass1 === chrPass2) {
        SamePass0++;
      } else {
        SamePass0 = 0;
      }
      // 연속성(+) 카운드
      if (chrPass0 - chrPass1 === 1 && chrPass1 - chrPass2 === 1) {
        SamePass1++;
      } else {
        SamePass1 = 0;
      }
      // 연속성(-) 카운드
      if (chrPass0 - chrPass1 === -1 && chrPass1 - chrPass2 === -1) {
        SamePass2++;
      } else {
        SamePass2 = 0;
      }
    }

    if (SamePass0 > 0) {
      userPwPassed = false;
    }
    if (SamePass1 > 0 || SamePass2 > 0) {
      alert('영문, 숫자는 3자 이상 연속으로 입력할 수 없습니다.');
      return false;
    }
    if (!userPwPassed) {
      alert('동일 문자를 3자 이상 연속으로 입력할 수 없습니다.');
      return false;
    }
  }
  return true;
};
export const calcUnit = (value, area) => {
  return value / area;
};
export const calcUnitCut = (value, unit) => {
  if (isNaN(value)) value = 0;
  return Math.floor(value / unit) * unit;
};
export const convertPyeong = (value, flag = false) => {
  if (this.isEmpty(value)) return 0;
  return flag ? value / 3.305785 : value * 3.305785;
};
export const fnConvertSssan = (val) => {
  return val === '1' ? '' : '산';
};
export const focusOnInvalid = () => {
  const timeout = setTimeout(() => {
    const invalids = document.getElementsByClassName('is-invalid');
    const filtered = [...invalids].filter((element) => element.tagName.toLowerCase() === 'div');
    if (filtered && filtered.length > 0) {
      const target = filtered[0];
      target.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
    clearTimeout(timeout);
  });
};
export const isValid = (v, el) => {
  v.$touch();
  const isInvalid = v.$invalid;
  if (isInvalid) {
    // const options = {
    //   container: 'body',
    //   offset: -60,
    //   onDone: function (el) {
    //     // let msg = el.getAttribute('msg') || '필수 입력 항목 입니다.'
    //     // alert(msg)
    //     el = el.querySelector('input') || el;
    //     if (el) {
    //       setTimeout(() => {
    //         el.focus();
    //       }, 100);
    //     }
    //   }
    // };
    setTimeout(() => {
      el.querySelector('.is-invalid').scrollIntoView({ behavior: 'smooth' });
      // VueScrollTo.scrollTo(el.querySelector('.is-invalid'), 500, options);
    }, 100);
    return false;
  }
  return true;
};
// api.ipify.org을 통해 public ip를 가져옴
export const getPublicIp = () => {
  // return fetch('https://api.ipify.org?format=json')
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', 'https://api.ipify.org?format=jsonp=', true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        resolve(xhr.responseText);
      }
    };
    xhr.send(null);
  });
};
export const maxLengthCheck = (object) => {
  if (object.value.length > object.maxLength) {
    object.value = object.value.slice(0, object.maxLength);
  }
};

/**
 * (shoppingValue) orderRequestNo로 주문시간 구하기
 * @param orderKey
 * @returns {string | *}
 */
export const parseOrderKey = (orderKey = '') => {
  if (orderKey.length > 0) return orderKey.substr(2, 14);
  else return orderKey;
};
export const convertTableData = (tableColumns, tableData) => {
  const list = [];
  tableColumns.forEach((column, index) => {
    const keyArr = column.prop ? column.prop.split('.') : [];
    const data = {};
    const fnFormatter = column.formatter ? column.formatter : '';
    tableData.forEach((item, itemIndex) => {
      let row = item;
      keyArr.forEach((key) => {
        row = row[key];
      });
      row = fnFormatter ? fnFormatter(item) : row;
      if (row || !this.isEmpty(row)) {
        data[`${column.label}`] = row;
        if (!list[itemIndex]) {
          list[itemIndex] = [];
        }
        if (!list[itemIndex][`${column.label}`]) {
          list[itemIndex][`${column.label}`] = {};
        }
        list[itemIndex][`${column.label}`] = row;
      }
    });
  });
  return list;
};
export const getImagePath = (path = '', size = 'origin', isFile = false) => {
  return path ? `${CODE_ENV_LIST.FILE_SERVER_URL}/${size}/${path}` : '';
};

export const convertURLtoFile = async (url) => {
  const fullUrl = getImagePath(url);
  const response = await fetch(url);
  const data = await response.blob();

  const ext = url.split('.').pop(); // url 구조에 맞게 수정할 것
  const filename = url.split('/').pop(); // url 구조에 맞게 수정할 것
  const metadata = { type: `image/${ext}` };

  return new File([data], filename, metadata);
};
export const toISO = (date) => {
  if (!date) return '-';

  if (date.includes('`T`')) return dayjs(date.replace('`T`', ' ')).format('YYYY.MM.DD hh:mm:ss');
  return dayjs(date).format('YYYY.MM.DD hh:mm:ss');
};
export const toLocalDate = (date) => {
  if (!date) return '-';

  if (date.includes('`T`')) return dayjs(date.replace('`T`', ' ')).format('YYYY.MM.DD');
  return dayjs(date).format('YYYY.MM.DD');
};
export const toLocalDateTime = (date) => toISO(date);
export const toLocalTime = (date) => {
  if (!date) return '-';

  if (date.includes('`T`')) return dayjs(date.replace('`T`', ' ')).format('Yhh:mm:ss');
  return dayjs(date).format('hh:mm:ss');
};
export const toDot = (price = 0 | '') => {
  let num = '' + price;
  if (num.includes(',')) {
    num = unDot(num);
  }
  if (Number.isNaN(Number(num))) return '-';
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const unDot = (price = '') => {
  return price.replaceAll(',', '');
};

import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["data-bs-toggle", "href", "aria-controls", "aria-expanded"];
const _hoisted_2 = {
  class: "icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("a", _mergeProps({
    "data-bs-toggle": $props.collapse ? 'collapse' : '',
    href: $props.collapse ? `#${$props.collapseRef}` : $props.collapseRef,
    "aria-controls": $props.collapseRef,
    "aria-expanded": $data.isExpanded,
    class: "nav-link"
  }, _ctx.$attrs, {
    onClick: _cache[0] || (_cache[0] = $event => $data.isExpanded = !$data.isExpanded)
  }), [_createElementVNode("div", _hoisted_2, [_renderSlot(_ctx.$slots, "icon")]), _createElementVNode("span", {
    class: _normalizeClass(["nav-link-text", _ctx.$store.state.isRTL ? ' me-1' : 'ms-1'])
  }, _toDisplayString($props.navText), 3)], 16, _hoisted_1), _createElementVNode("div", {
    class: _normalizeClass($data.isExpanded ? 'collapse show' : 'collapse')
  }, [_renderSlot(_ctx.$slots, "list")], 2)], 64);
}
import ServiceExec, { customToast } from '@/common/utils/ServiceExec';
import { admin } from '@/common/service/api/apiList';

const actions = {
  login: async ({ commit }, params) => {
    const { status, data, header } = await ServiceExec.patch(admin.logIn, params);

    if (await customToast(status, data, '로그인에 성공하였습니다.')) {
      const { authorization } = header; //TODO HEADER TOKEN ADMIN API
      commit('loginData', data);
      commit('accessToken', authorization);
      return true;
    } else return false;
  },
  logout: ({ commit }) => {
    commit('loginData');
    commit('accessToken');
  }
};

export default actions;

import { isEmpty } from '@/common/utils/Util';
export const assist = (parameter = {}, page = 1) => {
  if (parameter.page) parameter.page = page;

  if (isEmpty(parameter.searchDate)) {
    parameter.startYmd = '';
    parameter.endYmd = '';
    return parameter;
  }

  if (parameter.searchDate && !parameter.searchDate.includes('~')) {
    parameter.startYmd = parameter.searchDate.replace(/-/g, '');
    parameter.endYmd = parameter.searchDate.replace(/-/g, '');
  }

  if (parameter.searchDate && parameter.searchDate.includes('~')) {
    parameter.startYmd = parameter.searchDate.split('~')[0].trim().replace(/-/g, '');
    parameter.endYmd = parameter.searchDate.split('~')[1].trim().replace(/-/g, '');
  }

  return parameter;
};

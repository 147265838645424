import { createApp } from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';

import '@/assets/scss/AppgScss.scss';
import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';

import VueTilt from 'vue-tilt.js';
import VueSweetalert2 from 'vue-sweetalert2';
import ArgonDashboard from './argon-dashboard';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fas, fab, far);

const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);
app.use(store);
app.use(router);
app.use(VueTilt);
app.use(VueSweetalert2);
app.use(ArgonDashboard);
app.use(ElementPlus);
app.mount('#app');

import router from '@/router'
import {isRef, isReactive} from 'vue'
import store from '@/store'
import {cloneDeep} from "lodash";
export const pushAndSaveQueries = ( path = '/', queries= {}) => {
  let query
  if (isRef(queries)) query = cloneDeep(queries.value)
  if (isReactive(queries)) query = cloneDeep(queries)
  query.currentPath = location.pathname
  store.commit('layout/setQuery', query)
  router.push(path)
}
export const recoverFromQueries = ( params = {} ) => {
  const queries = cloneDeep(store.getters['layout/getQueries'])
  if(queries.currentPath === location.pathname) {
    Object.keys(queries)
      .forEach(key => {
        if (isRef(params)) params.value[key] = queries[key]
        if (isReactive(params)) params[key] = queries[key]
      })
  }
  store.commit('layout/setQuery')
}

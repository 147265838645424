import ServiceExec, { customToast } from '@/common/utils/ServiceExec';
import { fee } from '@/common/service/api/apiList';
import { cloneDeep } from 'lodash';
import { isEmpty } from '@/common/utils/Util';

const actions = {
  getList: async ({ commit }, params) => {
    commit('setConditions', params);

    const { status, data } = await ServiceExec.get(fee.getList, params);

    if (status !== ServiceExec.status.OK) return;

    const { pager, list } = data;

    list.forEach((item) => {
      item['benefit1'] = 0;
      if (isEmpty(item.contentBenefit)) return;
      const benefitData = JSON.parse(item.contentBenefit); // JSON 데이터 파싱
      item['benefit1'] = benefitData.benefitList.benefitMetas.map((item) => item.title).join(', ');
    });

    commit('setPageInfo', pager);
    commit('setList', list);
  },
  excelList: async ({ state, getters }) => {
    const { page } = getters['getPageInfo'];
    const { params } = state;
    const _params = cloneDeep(params);
    _params.limit = 10;
    _params.page = page;
    const { status, data } = await ServiceExec.get(fee.getList, _params);
    if (status !== ServiceExec.status.OK) return [];
    const { list } = data;
    return list;
  },
  getDetail: async ({ commit }, itemNo) => {
    commit('detailReset');
    const data = await detailData(itemNo);

    commit('setDetail', data);
  },
  updateItem: async ({ commit }, params) => {
    const { status, data } = await ServiceExec.post(fee.update, params);
    if (status !== ServiceExec.status.OK) return;
    await customToast(status, data, '수정이 완료되었습니다.');
    const detail = await detailData(params.itemNo);
    commit('setDetail', detail);
  }
};

const detailData = async (itemNo) => {
  const { status, data } = await ServiceExec.get(fee.getDetail(itemNo));
  if (status !== ServiceExec.status.OK) return;
  return data;
};

export default actions;

import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export const state = {
  // TODO ADMIN LOGIN API  수정
  loginData: {
    adminNo: '',
    id: '',
    joinType: '',
    role: ''
  },
  role: '',
  accessToken: ''
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

import logo from '@/assets/img/logo-ct-dark.png';
import logoWhite from '@/assets/img/logo-ct.png';
import image from '@/assets/img/profile-layout-header.jpg';
import ConstCode from '@/common/constants/ConstCode';
import SideNavList from '@/examples/Sidenav/sidenavList';
import { mapState } from 'vuex';
export default {
  name: 'Index',
  components: {
    SideNavList
  },
  data() {
    return {
      logo,
      logoWhite,
      image
    };
  },
  computed: {
    ...mapState('layout', ['layout', 'isRTL', 'sidebarType', 'darkMode']),
    projectName() {
      return ConstCode.CODE_ENV_LIST.PROJECT_NAME;
    },
    mainColor() {
      return ConstCode.MAIN_COLOR;
    },
    mainPath() {
      return ConstCode.MAIN_PATH;
    }
  }
};
const getters = {
  getBrandList: (state) => state.brandList,
  getCustomList: (state) => state.customList,
  getConnectList: (state) => state.connectList,
  getNetworkList: (state) => state.networkList,
  getStandardList: (state) => state.networkStandard,
  getServiceList: (state) => state.serviceList,
  getDetailNetwork: (state) => state.detailNetwork,
  getSuccessList: (state) => state.successList,
  getDetailBrand: (state) => state.detailBrand,
  getList: (state) => state.list,
  getPageInfo: (state) => state.pageInfo,
  getDetailData: (state) => (index) => state.list[index],
  getDetail: (state) => state.detail,
  getDetailBenefit: (state) => state.benefitData
};

export default getters;

import { forEach } from 'lodash';

export const asFormData = (parameter) => {
  let formData = new FormData();
  terraforming(formData, parameter);
  return formData;
};

const terraforming = (formData = new FormData(), parameter, prevKey = '') => {
  if (parameter instanceof Object && !Array.isArray(parameter) && !(parameter instanceof File)) {
    forEach(parameter, (value, key) => {
      terraforming(formData, value, prevKey ? `${prevKey}.${key}` : key);
    });
  } else if (parameter instanceof Array && Array.isArray(parameter)) {
    parameter.forEach((value, index) => {
      terraforming(formData, value, prevKey ? `${prevKey}[${index}]` : `[${index}]`);
    });
  } else {
    emptyValueCoverage(formData, prevKey, parameter);
  }
};

const emptyValueCoverage = (formData = new FormData(), prevKey = '', parameter) => {
  if (typeof parameter === 'boolean' || (parameter && parameter !== 'null')) formData.append(prevKey, parameter);
};

export default {
  name: "SidenavItem",
  props: {
    to: {
      type: [Object, String],
      required: true
    },
    miniIcon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  }
};
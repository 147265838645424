import axios from 'axios';
import store from '@/store';
import queryString from 'query-string';
import ConstCode from '@/common/constants/ConstCode';
import { debounce } from 'lodash';
export * from '@/common/utils/module/ToastServiceResultSupport';
const scrollIsLocked = (locked) => {
  document.getElementsByTagName('main')[0].style = `overflow:${locked ? 'hidden' : 'auto'}`;
};

const loading = () => {
  store.commit('layout/updateIsLoading', true);
  scrollIsLocked(true);
};
const loaded = debounce(() => {
  store.commit('layout/updateIsLoading', false);
  scrollIsLocked(false);
}, 500);

class ServiceExec {
  constructor() {
    let axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(this.handleRequest);
    axiosInstance.interceptors.response.use(this.handleSuccess, this.handleError);
    // axiosInstance.defaults.headers.common[ConstCode.TOKEN_NAME] = ConstCode.TOKEN_VALUE;
    axiosInstance.defaults.paramsSerializer = function (params) {
      return queryString.stringify(params, {
        arrayFormat: 'repeat',
        allowDots: true
      });
    };
    console.log('AXIOS', axiosInstance);
    this.ServiceExec = axiosInstance;
    this.store = store;
  }

  async handleRequest(request) {
    loading();
    const accessToken = store.getters['account/accessToken'];
    if (accessToken) {
      request.headers[ConstCode.LOGIN_TOKEN_NAME] = `${accessToken}`;
    }
    request.headers[ConstCode.TOKEN_NAME] = ConstCode.TOKEN_VALUE;

    console.log('AXIOS REQUEST', request);
    return request;
  }
  async handleSuccess(response) {
    loaded();
    return response;
  }
  async handleError({ response }) {
    loaded();
    console.log('ERROR', resposne);
    return Promise.resolve(response);
  }

  status = {
    OK: 200,
    ERROR: 500,
    NOT_FOUND: 404,
    UNAUTHORIZED: 401
  };

  get(path, params = {}) {
    return this.ServiceExec.request({
      method: 'GET',
      url: path,
      responseType: 'json',
      params: params,
      paramsSerializer: (params) =>
        queryString.stringify(params, {
          arrayFormat: 'indices',
          skipEmptyString: true,
          skipNulls: true,
          allowDots: true
        })
    });
  }

  put(path, payload) {
    return this.ServiceExec.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload
      // params: payload,
      // paramsSerializer: (payload) =>
      //   qs.stringify(payload, { arrayFormat: 'repeat' })
    });
  }
  patch(path, payload) {
    return this.ServiceExec.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload
    });
  }
  post(path, payload) {
    return this.ServiceExec.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    });
  }
  put(path, payload) {
    return this.ServiceExec.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload
    });
  }
  delete(path, payload) {
    payload = `${path}?${queryString.stringify(payload)}`;
    return this.ServiceExec.delete(payload);
  }

  postWithProgressBar(path, payload) {
    return this.ServiceExec.post(path, payload, {
      onUploadProgress: (progressEvent) => {
        store.commit('layout/setIsProgressBar', true);

        let percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        store.commit('layout/setProgress', percentage);

        if (percentage >= 100) {
          const timeout = setTimeout(() => {
            clearTimeout(timeout);
            store.commit('layout/setIsProgressBar', false);
          }, 500);
        }
      },
      header: {
        'Response-Type': 'application/json'
      }
    });
  }

  getWithProgressBar(path, payload) {
    return this.ServiceExec.get(path, payload, {
      onUploadProgress: (progressEvent) => {
        store.commit('layout/setIsProgressBar', true);

        let percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        store.commit('layout/setProgress', percentage);

        if (percentage >= 100) {
          const timeout = setTimeout(() => {
            clearTimeout(timeout);
            store.commit('layout/setIsProgressBar', false);
          }, 500);
        }
      },
      header: {
        'Response-Type': 'application/json'
      }
    });
  }

  queryPost(path, payload) {
    payload = `${queryString.stringify(payload)}`;
    return this.ServiceExec.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    });
  }
  multiPost(path, payload) {
    return this.ServiceExec.request({
      headers: { 'Content-Type': 'multipart/form-data' },
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    });
  }
}

let instance;
const initialize = () => {
  if (!instance) instance = new ServiceExec();

  return instance;
};
export default initialize();

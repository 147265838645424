import { toastAlert } from '@/common/utils/Util';

const sleep = (timeout) =>
  new Promise((resolve) => {
    setTimeout(() => resolve(undefined), timeout);
  });

const updateToast = async (status, data) => {
  await sleep(500);

  if (status !== 200) {
    toastAlert(data, 'error');
    return false;
  } else {
    toastAlert('수정에 성공했습니다.', 'success');
    return true;
  }
};

const insertToast = async (status, data) => {
  await sleep(500);

  if (status !== 200) {
    toastAlert(data, 'error');
    return false;
  } else {
    toastAlert('등록에 성공했습니다.', 'success');
    return true;
  }
};
const removeToast = async (status, data) => {
  await sleep(500);

  if (status !== 200) {
    toastAlert(data.msg, 'error');
    return false;
  } else {
    toastAlert('삭제에 성공했습니다.', 'success');
    return true;
  }
};
const customToast = async (status, data, message) => {
  await sleep(500);

  if (status !== 200) {
    toastAlert(data, 'error');
    return false;
  } else {
    if (!message) return true;
    toastAlert(message, 'success');
    return true;
  }
};

export { updateToast, insertToast, removeToast, customToast };

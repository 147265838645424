import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  id: "sidenav-collapse-main",
  class: "collapse navbar-collapse w-auto h-auto h-100"
};
const _hoisted_2 = {
  class: "navbar-nav"
};
const _hoisted_3 = {
  class: "nav ms-4"
};
import SidenavItem from './SidenavItem.vue';
import SidenavCollapse from './SidenavCollapse.vue';
import SidenavCollapseItem from './SidenavCollapseItem.vue';
import ConstCode from '@/common/constants/ConstCode';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
export default {
  __name: 'sidenavList',
  setup(__props) {
    const {
      NAVIGATION_ITEM
    } = ConstCode;
    const route = useRoute();
    const navigation = computed(() => NAVIGATION_ITEM);
    const getRoute = () => {
      return true;
    };
    const coloring = color => {
      if (!color) return 'black';
      return color;
    };
    return (_ctx, _cache) => {
      const _component_font_awesome_icon = _resolveComponent("font-awesome-icon");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("ul", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navigation.value, nav => {
        return _openBlock(), _createElementBlock("li", {
          class: "nav-item",
          key: nav.title
        }, [_createVNode(SidenavCollapse, {
          "collapse-ref": `${nav.title}_ref`,
          "nav-text": nav.title
        }, {
          icon: _withCtx(() => [_createVNode(_component_font_awesome_icon, {
            icon: nav.icon.name,
            size: nav.icon.size ?? 'lg',
            style: _normalizeStyle({
              color: nav.icon.color ?? 'black'
            })
          }, null, 8, ["icon", "size", "style"])]),
          list: _withCtx(() => [_createElementVNode("ul", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(nav.inner, innerItem => {
            return _openBlock(), _createElementBlock(_Fragment, {
              key: `${nav.title}_${innerItem.title}`
            }, [!!innerItem?.inner ? (_openBlock(), _createBlock(SidenavCollapseItem, {
              key: 0,
              refer: `${innerItem.title}_ref`,
              "mini-icon": "",
              text: innerItem.title
            }, {
              "nav-child-item": _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(innerItem.inner, deepItem => {
                return _openBlock(), _createBlock(SidenavItem, {
                  key: `${nav.title}_${innerItem.title}_${deepItem.title}`,
                  to: deepItem.to,
                  "mini-icon": "",
                  text: deepItem.title
                }, null, 8, ["to", "text"]);
              }), 128))]),
              _: 2
            }, 1032, ["refer", "text"])) : _createCommentVNode("", true), !innerItem?.inner ? (_openBlock(), _createBlock(SidenavItem, {
              key: 1,
              to: innerItem.to,
              "mini-icon": "",
              text: innerItem.title
            }, null, 8, ["to", "text"])) : _createCommentVNode("", true)], 64);
          }), 128))])]),
          _: 2
        }, 1032, ["collapse-ref", "nav-text"])]);
      }), 128))])]);
    };
  }
};
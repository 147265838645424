import { utils, writeFile, read } from 'xlsx';
import dayjs from 'dayjs';
const { json_to_sheet, sheet_to_json, book_new, book_append_sheet } = utils;

const isEmpty = (value) => {
  return (
    value === '' || value === null || value === undefined || (typeof value === 'object' && !Object.keys(value).length)
  );
};

const convertTableData = (tableColumns, tableData) => {
  const list = [];

  tableColumns.forEach((column, index) => {
    const keyArr = column.prop ? column.prop.split('.') : [];
    const data = {};
    const fnFormatter = column.formatter ? column.formatter : '';
    tableData.forEach((item, itemIndex) => {
      let row = item;

      keyArr.forEach((key, idx) => {
        row = row[key];
      });

      row = fnFormatter ? fnFormatter(item) : row;

      if (row || !isEmpty(row)) {
        data[`${column.label}`] = row;
        if (!list[itemIndex]) {
          list[itemIndex] = [];
        }

        if (!list[itemIndex][`${column.label}`]) {
          list[itemIndex][`${column.label}`] = {};
        }
        list[itemIndex][`${column.label}`] = row;
      }
    });
  });
  return list;
};

const hasExceptionalExtensions = ({ type }) => {
  const acceptableExtension = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/haansoftxlsx'
  ];

  return !acceptableExtension.includes(type);
};
const parsingEngine = (file = null, format = new XlsxFormat()) => {
  return new Promise((resolve, reject) => {
    if (!file || format.getSheetFormat().length == 0) reject(null);

    const reader = new FileReader();
    reader.onload = (evnt) => {
      const data = reader.result;
      const workbook = read(data, { type: 'binary' });
      const targetSheet = workbook.SheetNames.filter((shtNm) => shtNm === format.getSheetName())?.pop() ?? null;

      if (!targetSheet) reject(null);

      const columns = format.getSheetFormat();
      try {
        for (const column of columns) {
          const { position, jsonKey } = column;
          workbook.Sheets[targetSheet][position].w = jsonKey;
        }
      } catch (e) {
        reject(null);
      }

      resolve(sheet_to_json(workbook.Sheets[targetSheet]));
    };

    reader.readAsBinaryString(file);
  });
};

export class XlsxFormat {
  #sheetFormat = [];
  #sheetName = '';
  constructor(sheetName = '') {
    this.#sheetFormat = [];
    this.#sheetName = sheetName;
  }
  getSheetName() {
    return this.#sheetName;
  }

  getSheetFormat() {
    return this.#sheetFormat;
  }
  appendColumn(row, column, jsonKey = '') {
    const alphabet = /[A-Za-z]/g;
    const digit = /\d/g;

    if (!alphabet.test(row) || !digit.test(column)) {
      throw new Error('row, column이 잘못됐습니다. row는 영문 대소문자, column은 숫자만 입력 가능합니다.');
    }
    this.#sheetFormat.push({ position: `${row.toUpperCase()}${column}`, jsonKey });
  }
}
export const importFromXlsx = async ({ target = null }, format = new XlsxFormat()) => {
  const fileStack = target?.files;
  if (!format instanceof XlsxFormat) throw new Error('XlsxFormat class를 이용해주세요.');
  if (!fileStack || (fileStack?.length ?? 0) == 0) {
    target.value = '';
    return;
  }
  const file = fileStack[0];
  if (hasExceptionalExtensions(file)) return;

  const parsedXl = await parsingEngine(file, format);
  if (parsedXl.length === 0) return;

  return parsedXl;
};
export const exportAsXlsx = (columns, list, fileName = '') => {
  const sheet = json_to_sheet(convertTableData(columns, list));
  const workbook = book_new();
  book_append_sheet(workbook, sheet);
  const title = `${fileName}_${dayjs().format('YYYY.MM.DD')}.xlsx`;
  writeFile(workbook, title);
};

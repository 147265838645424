import { mapState, mapMutations, mapActions } from 'vuex';
export default {
  name: 'Navbar',
  components: {},
  data() {
    return {
      showMenu: false
    };
  },
  computed: {
    ...mapState('layout', ['isRTL', 'isNavFixed', 'darkMode']),
    name() {
      return this.$store.getters['account/loginData']?.name ?? '관리자';
    },
    currentRouteName() {
      return this.$route.name;
    },
    currentDirectory() {
      let dir = this.$route.path.split('/')[1];
      if (dir.length > 0) {
        return dir.charAt(0).toUpperCase() + dir.slice(1);
      }
    }
  },
  created() {
    this.minNav;
  },
  beforeUpdate() {
    this.toggleNavigationOnMobile();
  },
  methods: {
    ...mapMutations('layout', ['navbarMinimize', 'toggleConfigurator']),
    ...mapActions('layout', ['toggleSidebarColor']),
    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        this.navbarMinimize();
      }
    },
    logOut() {
      this.$store.dispatch('admin/logout');
      this.$router.replace('/login');
    }
  }
};
import { createRouter, createWebHistory } from 'vue-router';
import BasePath from '@/router/module/basePath';
import FeePath from '@/router/module/feePath';
import LogPath from '@/router/module/logPath';
import { notRegisteredRoutePath, sessionInvalidateCheck, tokenCheck } from '@/router/navigationGuard';

const routes = [...BasePath, ...FeePath, ...LogPath];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active'
});

// TODO sessionInvalidateCheck Admin Login API Update
router.beforeEach(async (to, from, next) => {
  if (true) next();

  if (!tokenCheck(to, from, next) && !sessionInvalidateCheck(to, from, next) && !notRegisteredRoutePath(to, from, next))
    next('/login');
  else next();
});

export default router;

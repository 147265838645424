export const getScrollTop = () => {
  const el = document.getElementById('wrap');
  return el.scrollTop;
};
export const scrollByWrap = (position, el) => {
  el.scrollBy(0, position);
};
export const scrollToTop = (elId, scrollDuration = 300) => {
  let _this = this;
  const el = document.getElementById(elId);
  let scrollStep = -el.scrollTop / (scrollDuration / 15);
  let scrollInterval = setInterval(function () {
    if (el.scrollTop !== 0) {
      _this.scrollByWrap(scrollStep, el);
      // el.scrollBy(0, scrollStep)
    } else {
      clearInterval(scrollInterval);
    }
  }, 12);
};
export const scrollToEl = (el, scrollDuration = 300) => {
  let _this = this;
  let scrollStep = -el.scrollTop / (scrollDuration / 15);
  let scrollInterval = setInterval(function () {
    if (el.scrollTop !== 0) {
      _this.scrollByWrap(scrollStep, el);
      // el.scrollBy(0, scrollStep)
    } else {
      clearInterval(scrollInterval);
    }
  }, 12);
};
export const scrollLock = (isLock = false) => {
  document.body.getElementsByTagName('main')[0].style.overflow = isLock ? 'hidden' : 'auto';
};

import ConstCode, { SERVICE_TYPE } from '@/common/constants/ConstCode';

export const SUPPORT = {
  NOT: {
    value: 0,
    label: '미지원'
  },
  ON: {
    value: 1,
    label: '지원'
  }
};

export const PICK_SEARCH_BTN = {
  BRAND: 'brand_conf',
  NETWORK: 'network',
  CUSTOM: 'custom',
  CONNECT: 'connect'
};
export const PICK_DELETE_OPTION = {
  SERVICE: 'serviceMetas',
  BENEFIT: 'benefitMetas',
  CARD: 'cardMetas'
};

export const itemEntity = {
  itemNo: '',
  finalUrl: '', // URL
  week: 0,
  brandFk: 0,
  groupFK: 0,
  score: 0.0,
  weekRank: 0,
  weekRankDiff: 0,
  weekCreditRank: 0,
  weekCreditRankDiff: 0,
  weekPriceRank: 0,
  weekPriceRankDiff: 0,
  weekPopRank: 0,
  weekPopRankDiff: 0,
  weekConvRank: 0,
  weekConvRankDiff: 0,
  weekGiftRank: 0,
  weekGiftRankDiff: 0,
  price: 0,
  priceDiff: 0,
  afterPriceDiff: 0,
  boLowestPrice: 0,
  boHighestPrice: 0,
  rate: 0.0,
  priceRate: 0.0,
  popRate: 0.0,
  confRate: 0.0,
  convRate: 0.0,
  beneRate: 0.0,
  planName: '',
  planGroup: '',
  brandName: '',
  network: 'SKT',
  generation: 'LTE',
  data: 0,
  dataDay: 0,
  dataSpeedAfterLimit: 0,
  voice: -1,
  text: -1,
  hotspot: '',
  dataSharing: '미지원',
  videoExtraCharge: '',
  facePhoneWonPerSec: 0.0,
  addPhoneWonPerSec: 0.0,
  longSmsWonPerOne: 0.0,
  longSmsWithPhotoWonPerOne: 0.0,
  longSmsWithMovieWonPerOne: 0.0,
  shortSmsWonPerSec: 0.0,
  discountMonthlyFee: 0,
  discountMonths: 0, // 할인 개월수 0 :평생 ~ Number
  discountedFeeAfterMonths: 0,
  benefit1: '',
  microPayment: 0,
  internationalRoaming: 0,
  familyCombination: 0,
  internetCombination: 0,
  contentBenefit: null,
  pointBenefit: null,
  popular: 0,
  scorePrice: 0,
  scorePop: 0,
  scoreGift: 0,
  scoreCredit: 0,
  scoreConv: 0,
  itemScore: 0.0,
  brandScore: 0.0,
  simCard: 0,
  nfcSimCard: 0,
  esim: 0,
  selfActivation: 0,
  aiActivation: 0,
  mycall: 0,
  mychat: 0,
  twentyFourSevenSupport: 0,
  mobileApp: 0,
  agreementPeriod: '',
  tdata: '',
  dataWonPerMb: '',
  uptDT: '',
  readConnect: 0,
  readDetail: 0,
  brand: {
    salestr: '',
    brandNo: '',
    conf: 6,
    isMno: 0,
    title: '',
    network: '',
    simCard: 0,
    nfcSimCard: 0,
    esim: 0,
    selfActivation: 0,
    aiActivation: 0,
    mycall: 0,
    mychat: 0,
    twentyFourSevenSupport: 0,
    mobileApp: 0,
    aosScore: 0.0,
    iosScore: 0.0,
    tag: '',
    mvnoTitle: '',
    allNetwork: false,
    appScore: 0
  },
  group: null,
  uidInGroup: ''
};
export const itemBenefitEntity = {
  benefitList: {
    amountPointBenefit: 0,
    benefitMetas: [
      {
        title: '' // ex) 스타벅스
      }
    ],
    cardMetas: [
      {
        company: '', // 카드사명
        priceStr: '' // ex) 월 30,000원
      }
    ],
    serviceMetas: [
      {
        type: '',
        title: ''
      }
    ]
  }
};

export const feeDetailList = {
  discountMonth: [
    {
      value: 0,
      label: '평생'
    },
    {
      value: 1,
      label: '개월'
    }
  ],
  agreementPeriod: [
    {
      value: 0,
      label: '무약정'
    },
    {
      value: 1,
      label: '개월'
    }
  ],
  dataSharing: (modelValue) => {
    modelValue = modelValue === '미지원' ? '' : modelValue;
    return [
      {
        value: '미지원',
        label: '미지원'
      },
      {
        value: modelValue,
        label: '지원'
      }
    ];
  },

  voiceAndText: [
    {
      value: -1,
      label: '무제한'
    },
    {
      value: 0,
      label: '실속'
    }
  ],
  support: [
    {
      value: 1,
      label: '지원'
    },
    {
      value: 0,
      label: '미지원'
    }
  ],
  roaming: [
    {
      value: 0,
      label: '미지원'
    },
    {
      value: 1,
      label: '통신사'
    },
    {
      value: 2,
      label: '자체'
    },
    {
      value: 3,
      label: '통신사/자체'
    }
  ],
  content: [
    {
      value: 0,
      label: '콘텐츠'
    },
    {
      value: 1,
      label: '포인트'
    }
  ]
};

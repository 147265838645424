import qs from 'query-string';

export const getBirthAge = birth => {
  let today = new Date();
  let age = today.getFullYear() - birth.getFullYear();
  birth.setFullYear(today.getFullYear());
  if (today < birth) age--;
  return age;
};
export const getAgeToDate = (age, birth) => {
  let years = birth.getFullYear() + age;
  birth.setFullYear(years);
  return birth;
};
export const secondsToTimes = (value, format = [':', ':', '']) => {
  value = parseInt(value);
  let hour = Math.floor(value / 3600);
  let minutes = Math.floor((value - hour * 3600) / 60);
  let seconds = value - hour * 3600 - minutes * 60;
  let covHour = this.formatLpad(hour, 0, '2');
  let covMinutes = this.formatLpad(minutes, 0, '2');
  let covSeconds = this.formatLpad(seconds, 0, '2');
  return `${covHour}${format[0]}${covMinutes}${format[1]}${covSeconds}${format[2]} `;
  // if (this.isEmpty(format)) return `${this.formatLpad(hour, 0, '2')}:${this.formatLpad(minutes, 0, '2')}:${this.formatLpad(seconds, 0, '2')}`
};
export const objectToQuerystring = obj => qs.stringify(obj);

export default {
  getBirthAge,
  getAgeToDate,
  secondsToTimes,
  objectToQuerystring
};

const state = {
  hideConfigButton: false,
  isPinned: true,
  showConfig: false,
  isRTL: false,
  color: '',
  sidebarType: 'bg-white',
  darkMode: false,
  isNavFixed: false,
  isAbsolute: false,
  showNavs: true,
  showSidenav: true,
  showNavbar: true,
  showFooter: true,
  showMain: true,
  isLoading: false,
  layout: 'default',
  query: {},
  progress: 0,
  isProgressBar: false,
  keepAlive: []
};
export default state;

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "nav-item"
};
const _hoisted_2 = {
  class: "sidenav-mini-icon"
};
const _hoisted_3 = {
  class: "sidenav-normal"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("li", _hoisted_1, [_createVNode(_component_router_link, {
    class: "nav-link",
    to: $props.to
  }, {
    default: _withCtx(() => [_createElementVNode("span", _hoisted_2, _toDisplayString($props.miniIcon), 1), _createElementVNode("span", _hoisted_3, _toDisplayString($props.text), 1)]),
    _: 1
  }, 8, ["to"])]);
}
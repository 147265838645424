export const review = {
  review: '/admin/review'
  // HTTP METHOD : GET
  // PARAMETERS :
};

/* ----알랭 API ---- */

export const fee = {
  getList: '/item/getListForAdmin',
  getDetail: (itemNo) => `/item/getDetail?itemNo=${itemNo}`,
  update: '/item/update'
};

/* ----알랭 API ---- */

export const admin = {
  logOut: '/admin/log/out',
  // HTTP METHOD : POST
  // PARAMETERS : AdminDto, HttpServletResponse,
  changePassword: '/admin/change-password',
  // HTTP METHOD : PATCH
  // PARAMETERS : AdminChangePasswordRequest,
  logIn: '/admin/login' // TODO LOGIN API 주소 수정 필
  // HTTP METHOD : PATCH
  // PARAMETERS : AdminLoginRequest, HttpServletResponse,
};

export const common = {
  lambda$toMultiValueMap$1: '/common',
  // HTTP METHOD :
  // PARAMETERS : MultiValueMap, String, String,
  lambda$restRequest$0: '/common',
  // HTTP METHOD :
  // PARAMETERS : UriComponentsBuilder, String, String,
  refreshAccessToken: '/common/refresh/access-token',
  // HTTP METHOD : GET
  // PARAMETERS : RefreshAccessTokenDto, HttpServletResponse,
  getEnumValue: '/common/enum',
  // HTTP METHOD : GET
  // PARAMETERS :
  restRequest: '/common/rest',
  // HTTP METHOD : GET
  // PARAMETERS : RestRequestDto,
  versionCheck: '/common/version-check',
  // HTTP METHOD : GET
  // PARAMETERS : UserDeviceDto,
  toMultiValueMap: '/common',
  // HTTP METHOD :
  // PARAMETERS : Map,
  download: '/common/download',
  // HTTP METHOD : GET
  // PARAMETERS : String, String,
  email: '/common/email',
  // HTTP METHOD : GET
  // PARAMETERS :
  sendPush: '/common/sendPush',
  // HTTP METHOD : GET
  // PARAMETERS :
  uploadSingleMultipartFile: '/common/upload',
  // HTTP METHOD : POST
  // PARAMETERS : MultipartFile,
  uploadMultipartFile: '/common/upload/multi'
  // HTTP METHOD : POST
  // PARAMETERS : MultipartFile;,
};

export const board = {
  boardOne: '/api/board',
  // HTTP METHOD : GET
  // PARAMETERS :
  boards: '/api/board/list',
  // HTTP METHOD : GET
  // PARAMETERS :
  board: '/api/board',
  // HTTP METHOD : POST
  // PARAMETERS : BoardDto,
  boardFiltered: '/api/board/filtered'
  // HTTP METHOD : POST
  // PARAMETERS : BoardDto,
};

export const user = {
  join: '/api/user/join',
  // HTTP METHOD : POST
  // PARAMETERS : UserJoinRequest, HttpServletResponse,
  logOut: '/api/user/log/out',
  // HTTP METHOD : POST
  // PARAMETERS : UserLogOutRequest, HttpServletResponse,
  getUser: '/api/user/information',
  // HTTP METHOD : GET
  // PARAMETERS : Principal,
  renewDeviceInfo: '/api/user/renew/device-info',
  // HTTP METHOD : PATCH
  // PARAMETERS : UserRenewDeviceInfoRequest,
  logIn: '/api/user/log/in'
  // HTTP METHOD : PATCH
  // PARAMETERS : UserLogInRequest, HttpServletResponse,
};

//Project Name : null//Created at 2023-12-01 04:08:30

import { cloneDeep } from 'lodash';
import { isEmpty } from '@/common/utils/Util';

const mutations = {
  setConditions: (state, { page, searchDate, searchValue, startYmd, endYmd, brand_conf, connect, custom }) => {
    state.params.page = page;
    state.params.searchValue = searchValue;
    state.params.startYmd = startYmd;
    state.params.endYmd = endYmd;
    state.params.brand_conf = brand_conf;
    state.params.custom = custom;
    state.params.connect = connect;
  },
  setPageInfo: (state, pageInfo) => {
    state.pageInfo = pageInfo;
  },
  setList: (state, list) => {
    state.list = cloneDeep(list);
  },
  setDetail: (state, data) => {
    const { contentBenefit } = data.detail;
    state.detail = data.detail;

    if (!isEmpty(contentBenefit)) {
      const { benefitMetas, cardMetas, serviceMetas, amountPointBenefit } = JSON.parse(contentBenefit).benefitList;
      state.benefitData.benefitList.amountPointBenefit = amountPointBenefit != null ? amountPointBenefit : 0;
      listSet(state, 'benefitMetas', benefitMetas);
      listSet(state, 'cardMetas', cardMetas);
      listSet(state, 'serviceMetas', serviceMetas);
    }
  },
  detailReset: (state) => {
    state.detail = {};
    state.benefitData.benefitList = {
      benefitMetas: [],
      cardMetas: [],
      serviceMetas: []
    };
  }
};

const listSet = (state, valueName, list) => {
  if (Array.isArray(list) && list.length !== 0) {
    state.benefitData.benefitList[valueName] = cloneDeep(list);
  } else {
    state.benefitData.benefitList[valueName] = [];
  }
};

export default mutations;

const mutations = {
  loginData(
    state, //TODO ADMIN  LOGIN API 수정
    data = {
      adminNo: '',
      id: '',
      joinType: '',
      role: ''
    }
  ) {
    state.loginData = data;
  },
  accessToken: (state, accessToken = null) => {
    state.accessToken = accessToken;
  }
};

export default mutations;

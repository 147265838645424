import Env from './Env.json';
import Env_stage from './Env_stage.json';
import Env_prod from './Env_prod.json';

export let PROFILE = 'develop';

export const TOKEN_NAME = 'token';
export const TOKEN_VALUE = 'tpwhdeodhkd2024';
export let CODE_ENV_LIST = Env;
if (process.env.NODE_ENV === 'production') {
  PROFILE = 'prod';
  CODE_ENV_LIST = Env_prod;
} else if (process.env.VUE_APP_MODE === 'stage') {
  PROFILE = 'test';
  CODE_ENV_LIST = Env_stage;
}
/**
 *  <h3> navigationItem icons </h3>
 *  <br/>
 * [Search Icons!](https://fontawesome.com/icons/)
 * <br/>
 * size :
 * ['2xs', 'xs', 'sm', 'lg', 'xl', '2xl', '1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x']
 */

export * from '@/common/constants/Enumerations';

export const BENEFIT_OPTION_TYPE = {
  CardMetas: 'cardMetas',
  ServiceMeats: 'serviceMetas',
  BenefitMetas: 'benefitMetas'
};

export const NAVIGATION_ITEM = [
  {
    title: '요금제',
    icon: {
      name: ['fas', 'note-sticky'],
      color: '#28df99'
    },
    color: '',
    inner: [
      {
        title: '요금제',
        to: '/fee'
      },
      {
        title: '로그',
        to: '/logs'
      }
    ]
  }
];
export const LOGIN_TOKEN_NAME = 'Authorization';
export const BOARD_TYPE = {
  NOTICE: { value: 'NOTICE', label: '공지사항' },
  FAQ: { value: 'FAQ', label: '자주묻는질문' }
};

export const SERVICE_TYPE = {
  CONTENT: 'CONTENT',
  POINT: 'POINT'
};

export const MAIN_COLOR = '#28df99'; //'#f84848';
export const MAIN_PATH = '/fee';
export const SESSION_TIME_OUT = {
  INFINITE: 0,
  nHour: (hour = 1) => {
    const result = hour * 60 * 60 * 1000;
    if (Number.isNaN(result)) return 1 * 60 * 60 * 1000;
    else return result;
  },
  nDay: (day = 1) => {
    const result = day * 24 * 60 * 60 * 1000;
    if (Number.isNaN(result)) return 1 * 24 * 60 * 60 * 1000;
    else return result;
  }
};
export default {
  CODE_ENV_LIST,
  LOGIN_TOKEN_NAME,
  NAVIGATION_ITEM,
  BOARD_TYPE,
  MAIN_PATH,
  MAIN_COLOR,
  TOKEN_VALUE,
  TOKEN_NAME,
  SERVICE_TYPE,
  BENEFIT_OPTION_TYPE
};

export default {
  name: "SidenavCollapseItem",
  props: {
    refer: {
      type: String,
      required: true
    },
    miniIcon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isExpanded: false
    };
  }
};
import store from '@/store';
import { SESSION_TIME_OUT } from '@/common/constants/ConstCode';
import routerInstance from '@/router/index';

/**
 * 토큰 체크
 */
const tokenCheck = (to, from, next) => {
  const accessToken = store.getters['admin/accessToken'];
  if (!accessToken && to.path !== '/' && to.path !== '/login') return false;
  else return true;
};

/**
 * 토큰 만료 시간 체크
 */
const sessionInvalidateCheck = (to, from, next) => {
  if (process.env.NODE_ENV !== 'production') return true;
  const start = store.getters['admin/sessionTime'];
  const now = new Date().getTime();
  if (to.path === '/' || to.path === '/login') return true;
  if (!start) return false;
  if ((to.path !== '/' || to.path !== '/login') && now - start > SESSION_TIME_OUT.nHour(1)) return false;
  else return true;
};

const notRegisteredRoutePath = (to, from, next) => {
  if (!routerInstance.hasRoute(to.name)) return false;
  else return true;
};

export { tokenCheck, sessionInvalidateCheck, notRegisteredRoutePath };

import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b15ba88e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "appg-progress-body"
};
const _hoisted_2 = {
  class: "barWrapper"
};
const _hoisted_3 = {
  class: "appg-progress percentage"
};
import Modal from '@/templates/argon-core/Modal.vue';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { MAIN_COLOR } from '@/common/constants/ConstCode';
export default {
  __name: 'AppgProgressBar',
  setup(__props) {
    const {
      getters
    } = useStore();
    const progress = computed(() => getters['layout/getProgress']);
    const isShow = computed(() => getters['layout/getIsProgressBar']);
    const bar = computed(() => `background: linear-gradient(90deg,  ${MAIN_COLOR} ${progress.value}%,  #eeeeee ${progress.value}%);`);
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(Modal, {
        show: isShow.value,
        size: 'md',
        "show-close": false
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
          class: "appg-progress bar",
          style: _normalizeStyle(bar.value)
        }, null, 4), _createElementVNode("h6", _hoisted_3, _toDisplayString(`${progress.value}%`), 1)])])]),
        _: 1
      }, 8, ["show"]);
    };
  }
};
const getters = {
  getIsLoading(state) {
    return state.isLoading;
  },
  getLayout: (state) => state,
  getQueries: (state) => state.query,
  getProgress: (state) => state.progress,
  getIsProgressBar: (state) => state.isProgressBar,
  getKeepAlive: (state) => state.keepAlive
};

export default getters;

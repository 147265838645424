import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "landing-bg h-100 bg-gradient-primary position-fixed w-100"
};
const _hoisted_2 = {
  class: "main-content position-relative max-height-vh-100 h-100"
};
import Sidenav from './examples/Sidenav';
import Navbar from '@/examples/Navbars/Navbar.vue';
import AppFooter from '@/examples/Footer.vue';
import Spinner from '@/components/custom/modal/AppgSpinner.vue';
import AppgProgressBar from '@/components/custom/modal/AppgProgressBar.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
  __name: 'App',
  setup(__props) {
    const {
      getters
    } = useStore();
    const keepAlive = computed(() => getters['layout/getKeepAlive']);
    const layout = computed(() => getters['layout/getLayout']);
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_withDirectives(_createElementVNode("div", _hoisted_1, null, 512), [[_vShow, layout.value?.layout === 'landing']]), layout.value?.showSidenav ? (_openBlock(), _createBlock(_unref(Sidenav), {
        key: 0
      })) : _createCommentVNode("", true), _createElementVNode("main", _hoisted_2, [layout.value?.showNavbar ? (_openBlock(), _createBlock(Navbar, {
        key: 0
      })) : _createCommentVNode("", true), _createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [(_openBlock(), _createBlock(_KeepAlive, {
          include: keepAlive.value
        }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1032, ["include"]))]),
        _: 1
      }), _withDirectives(_createVNode(Spinner, null, null, 512), [[_vShow, layout.value?.isLoading]]), _createVNode(AppgProgressBar), _withDirectives(_createVNode(AppFooter, null, null, 512), [[_vShow, layout.value?.showFooter]])])], 64);
    };
  }
};
/**
 * <h1  style="color:white;">Vuelidate Custom Message Utility</h1>
 *  <h2  style="color:white;"> usage</h2>
 *
 * <h3  style="color:white;"> html section </h3>
 *
 * <pre>
 *  <template>
 *   <div class="valid" v-if='v$.title.$error'>{{ validMessenger([v$.title.isEmpty]) || validMessenger([v$.title.titleLength])  }}</div>
 *  </template>
 * </pre>
 *
 * <hr/>
 * <h3 style="color:white;"> vuelidate Section </h3>
 *
 *
 * <pre>
 *   <script>
 *      const vuelidateRule = {
 *        title:{
 *          isEmpty: helpers.withMessage("제목을 입력해주세요.", (val) => !!val),
 *          titleLength: helpers.withMessage("제목을 15자 이내로 줄여주세요", (val) => val.length < 15)
 *        },
 *        content:{
 *          isEmpty: helpers.withMessage("내용을 입력해주세요.", (val) => !!val)
 *        }
 *      }
 *  </script>
 * </pre>
 *
 */

export const message = (valid, successMessage = '') => {
  const validators = Object.keys(valid).filter((key) => !key.includes('$'));
  for (let validKey of validators) {
    if (valid[validKey].$invalid) return valid[validKey].$message;
  }
  return successMessage;
};

export default { message };

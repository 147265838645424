import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import { itemBenefitEntity, itemEntity } from '@/common/table/FeeData';
import ConstCode from '@/common/constants/ConstCode';

export const state = {
  params: {
    page: 1,
    totalPage: 2,
    limit: 10,
    searchValue: '',
    startYmd: '',
    endYmd: '',
    brand_conf: [],
    connect: [],
    custom: []
  },
  benefitData: {
    ...itemBenefitEntity
  },
  detail: {
    ...itemEntity
  },
  list: [],
  successList: [
    {
      value: 0,
      label: '실패'
    },
    {
      value: 1,
      label: '성공'
    }
  ],
  pageInfo: {},
  brandList: [
    {
      value: 'all',
      label: '전체'
    },
    {
      value: 1,
      label: '대기업'
    },
    {
      value: 2,
      label: '천억대'
    },
    {
      value: 3,
      label: '중소(500억)'
    },
    {
      value: 4,
      label: '중소(100억)'
    },
    {
      value: 5,
      label: '중소(10억)'
    },
    {
      value: 6,
      label: '소기업'
    }
  ],
  connectList: [
    {
      value: 'all',
      label: '전체'
    },
    {
      value: 'simCard',
      label: '유심'
    },
    {
      value: 'nfcSimCard',
      label: 'NFC유심'
    },
    {
      value: 'esim',
      label: 'eSIM'
    },
    {
      value: 'selfActivation',
      label: '셀프개통'
    },
    {
      value: 'aiActivation',
      label: 'AI개통'
    }
  ],
  customList: [
    {
      value: 'all',
      label: '전체'
    },
    {
      value: 'call',
      label: '콜센터'
    },
    {
      value: 'chat',
      label: '채팅'
    },
    {
      value: 'twentyFourSevenSupport',
      label: '24시간'
    },
    {
      value: 'mobileApp',
      label: 'APP'
    }
  ],
  networkList: [
    {
      value: 'all',
      label: '전체'
    },
    {
      value: 'SKT',
      label: 'SKT'
    },
    {
      value: 'KT',
      label: 'KT'
    },
    {
      value: 'LGU',
      label: 'LG U+'
    }
  ],
  networkStandard: [
    {
      value: '5G',
      label: '5G'
    },
    {
      value: 'LTE',
      label: 'LTE'
    }
  ],
  detailNetwork: [
    {
      value: 'SKT',
      label: 'SKT'
    },
    {
      value: 'KT',
      label: 'KT'
    },
    {
      value: 'LGU',
      label: 'LG U+'
    }
  ],
  detailBrand: [
    {
      value: 1,
      label: '대기업'
    },
    {
      value: 2,
      label: '천억대'
    },
    {
      value: 3,
      label: '중소(500억)'
    },
    {
      value: 4,
      label: '중소(100억)'
    },
    {
      value: 5,
      label: '중소(10억)'
    },
    {
      value: 6,
      label: '소기업'
    }
  ],
  serviceList: [
    {
      value: ConstCode.SERVICE_TYPE.CONTENT,
      label: '콘텐츠'
    },
    {
      value: ConstCode.SERVICE_TYPE.POINT,
      label: '포인트'
    }
  ]
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import { sleep } from '@/common/utils/module/FlowSupport';

export const toastAlert = async (msg, form = '', isTheme = true) => {
  await sleep(500);
  switch (form) {
    case 'success':
      toast.success(msg, { autoClose: 1000, theme: isTheme ? 'colored' : 'auto' });
      break;
    case 'error':
      toast.error(msg, { autoClose: 1000, theme: isTheme ? 'colored' : 'auto' });
      break;
    case 'info':
      toast.info(msg, { autoClose: 1000, theme: isTheme ? 'colored' : 'auto' });
      break;
    case 'warning':
      toast.warning(msg, { autoClose: 1000, theme: isTheme ? 'colored' : 'auto' });
      break;
    default:
      toast(msg, { autoClose: 1000, theme: 'auto' });
      break;
  }
};

export const updateToast = (status, data) => {
  if (status !== 200) {
    toastAlert(data, 'error');
    return false;
  } else {
    toastAlert('수정에 성공했습니다.', 'success');
    return true;
  }
};

export const insertToast = (status, data) => {
  if (status !== 200) {
    toastAlert(data, 'error');
    return false;
  } else {
    toastAlert('등록에 성공했습니다.', 'success');
    return true;
  }
};
export const removeToast = (status, data) => {
  if (status !== 200) {
    toastAlert(data, 'error');
    return false;
  } else {
    toastAlert('삭제에 성공했습니다.', 'success');
    return true;
  }
};

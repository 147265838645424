import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';

import layout from './modules/layout/index';
import admin from './modules/admin/index';
import fee from './modules/fee/index';

export default createStore({
  state: {
    bootstrap
  },
  modules: {
    layout,
    admin,
    fee
  },
  plugins: [
    createPersistedState({
      paths: ['layout']
    })
  ]
});

export default [
  {
    path: '/',
    redirect: '/fee'
  },
  {
    path: '/fee',
    component: () => import('@/views/fee/FeeList.vue')
  },
  {
    path: '/fee/:itemNo',
    component: () => import('@/views/fee/FeeDetail.vue')
  }
];
